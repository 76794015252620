import React from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../context/ApiClientContext';

import MetadataTagTreeItem from './MetadataTagTreeItem';
import MetadataCategoryPane from './MetadataCategoryPane';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';

export default function MetadataCategoryTreeItem(props) {
  const {
    id,
    label,
    setFormPane,
    setTreeManagementServices,
    onDeleteWarningMessage,
    onDeleteConfirmed,
    refreshParent,
  } = props;

  const apiClient = useApiClient();

  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    expand,
    setExpand,
    setMCTreeItemChildNodes,
    updateLabel,
  } = useMedicalContentTreeItem(label);

  const fetchChildNodes = async () => {
    const results = await apiClient.getMetadataTagsByCategoryId(id);
    setMCTreeItemChildNodes(results.data.map((result) => (
      <MetadataTagTreeItem
        key={`${result.name}_${result.id}`}
        id={result.id}
        label={result.name}
        categoryId={result.categoryId}
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
        refreshParent={fetchChildNodes}
        onDeleteWarningMessage={`Do you want to permanently delete the metadata tag "${result.name}"?`}
        onDeleteConfirmed={() => apiClient.deleteMetadataTag(result.id)}
      />
    )));
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const onSelect = () => {
    setFormPane(
      <MetadataCategoryPane
        categoryId={id}
        updateCallback={updateLabel}
        label={label}
        refreshParent={reloadAndExpandNode}
      />,
    );
    setTreeManagementServices({
      onDeleteWarningMessage,
      deleteService: onDeleteConfirmed,
      onSuccess: refreshParent,
    });
  };

  return (
    <MedicalContentTreeItem
      id={id}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChilren="No metadata tag under this category yet..."
    />
  );
}

MetadataCategoryTreeItem.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  onDeleteWarningMessage: PropTypes.string.isRequired,
  onDeleteConfirmed: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../context/ApiClientContext';
import ScorePane from './ScorePane';
import ScoreFactorTreeItem from './ScoreFactorTreeItem';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';
import generateConditionLabel from '../Condition/generateConditionLabel';

export default function ScoreTreeItem(props) {
  const {
    scoreId, label, setFormPane, refreshParent, setTreeManagementServices,
  } = props;

  const {
    MCTreeItemLabel,
    updateLabel,
    MCTreeItemChildNodes,
    expand,
    setExpand,
    setMCTreeItemChildNodes,
  } = useMedicalContentTreeItem(label);

  const apiClient = useApiClient();

  const fetchChildNodes = async () => {
    const result = await apiClient.getFactorsForScore(scoreId);
    setMCTreeItemChildNodes(result.data.map((factor) => (
      <ScoreFactorTreeItem
        key={`scoreFactor_${factor.id}`}
        scoreId={scoreId}
        scoreFactor={factor}
        setTreeManagementServices={setTreeManagementServices}
        label={`${generateConditionLabel(factor.condition)} → ${factor.points > 0 ? '+' : ''}${factor.points}`}
        setFormPane={setFormPane}
        refreshParent={fetchChildNodes}
      />
    )));
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  // prefetch children to accurately display chevron
  useEffect(() => {
    fetchChildNodes();
  }, []);

  const onSelect = () => {
    setFormPane(
      <ScorePane
        key={`scorePane_${scoreId}`}
        scoreId={scoreId}
        updateCallback={updateLabel}
        reloadCallback={reloadAndExpandNode}
      />,
    );
    setTreeManagementServices({
      onDeleteWarningMessage: `Delete the Score "${label}"?`,
      deleteService: () => apiClient.deleteScore(scoreId),
      onSuccess: refreshParent,
    });
  };

  return (
    <MedicalContentTreeItem
      key={`score_${scoreId}`}
      id={scoreId}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      isEndNode={MCTreeItemChildNodes && MCTreeItemChildNodes.length === 0}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
    />
  );
}

ScoreTreeItem.propTypes = {
  scoreId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};

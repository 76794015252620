import React from 'react';
import PropTypes from 'prop-types';

import ScoreTreeItem from './ScoreTreeItem';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';

import { useApiClient } from '../../context/ApiClientContext';
import ScoreParentPane from './ScoreParentPane';

export default function ScoreParentTreeItem(props) {
  const { setFormPane, setTreeManagementServices } = props;
  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    expand,
    setExpand,
    setMCTreeItemChildNodes,
  } = useMedicalContentTreeItem('Scores');

  const apiClient = useApiClient();

  const fetchChildNodes = async () => {
    const results = await apiClient.getAllScores();
    setMCTreeItemChildNodes(results.data.map((score) => (
      <ScoreTreeItem
        key={`score_${score.id}`}
        scoreId={score.id}
        label={score.uniqueName}
        setTreeManagementServices={setTreeManagementServices}
        setFormPane={setFormPane}
        refreshParent={fetchChildNodes}
      />
    )));
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const onSelect = () => {
    setFormPane(
      <ScoreParentPane
        refreshParent={reloadAndExpandNode}
      />,
    );
    setTreeManagementServices({});
  };

  return (
    <MedicalContentTreeItem
      id={0}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChilren="No Scores yet..."
    />
  );
}

ScoreParentTreeItem.propTypes = {
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};

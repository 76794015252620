import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import MedicationSelector from '../../Medication/MedicationSelector';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../../context/ApiClientContext';
import ButtonWithModal from '../../../components/ButtonWithModal';
import useToast from '../../../hooks/useToast';
import basicServiceHandler from '../../../services/basicServiceHandler';

function AddMedicationToNarrativeButton(props) {
  const { actionListId, reloadCallback } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const apiClient = useApiClient();

  const {
    toastSetErrorNotification,
  } = useToast();

  const handleSubmit = async (medicationId) => {
    basicServiceHandler(
      () => apiClient.addMedicationToNarrative(actionListId, medicationId),
      () => { reloadCallback(); setModalIsOpen(false); },
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
    );
  };

  return (
    <ButtonWithModal
      buttonText="Add Medication To Narrative"
      modalTitle="Add Medication To Narrative"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <Grid container>
        <Grid item xs={6}>
          <MedicationSelector
            handleMedicationAdd={handleSubmit}
            buttonText="Add"
            successMsg="Success"
            reloadCallback={reloadCallback}
          />
        </Grid>
      </Grid>
    </ButtonWithModal>
  );
}

AddMedicationToNarrativeButton.propTypes = {
  actionListId: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func.isRequired,
};

export default AddMedicationToNarrativeButton;

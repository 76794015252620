import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useApiClient } from '../../../context/ApiClientContext';
import ButtonWithModal from '../../../components/ButtonWithModal';
import useToast from '../../../hooks/useToast';
import AttributeAndValueCrudForm from '../../Attribute/AttributeAndValueCrudForm';
import basicServiceHandler from '../../../services/basicServiceHandler';

export default function AddAttributeValueToNarrativeButton(props) {
  const { actionListId, reloadCallback } = props;

  const [attributeView, setAttributeView] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const apiClient = useApiClient();

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const makeAttributeCallbacks = (service, successMessage, errorMessage) => ({
    service,
    onSuccess: (result) => {
      setAttributeView(result.data);
      toastSetSuccessNotification(successMessage);
    },
    errorMsg: errorMessage,
  });

  const createAttributeCallbacks = makeAttributeCallbacks(
    (attributeCreateRequest) => apiClient.createAttribute(attributeCreateRequest),
    'You must now create a Value (may need to scroll down if you are on a small screen).',
  );

  const addExistingAttributeCallbacks = makeAttributeCallbacks(
    (attributeId) => apiClient.getAttributeById(attributeId),
    'You must now add a Value (may need to scroll down if you are on a small screen).',
  );

  const wrapUpAfterSuccess = (successMessage) => {
    setModalIsOpen(false);
    setAttributeView(null);
    reloadCallback();
    toastSetSuccessNotification(successMessage);
  };

  const createAttributeValueCallbacks = {
    service: (attributeValueId) => apiClient.createAttributeValue(
      attributeView.id,
      attributeValueId,
    ),
    onSuccess: (result) => {
      const attributeValueId = result.data.id;
      basicServiceHandler(
        () => apiClient.addAttributeValueToNarrative(actionListId, attributeValueId),
        () => wrapUpAfterSuccess('Success'),
        (error) => toastSetErrorNotification(`'Error creating attribute value': ${error}`),
        () => {},
      );
      wrapUpAfterSuccess('Success');
    },
    errorMsg: 'Error creating attribute value',
  };

  const addExistingAttributeValueCallbacks = ({
    service: (attributeValueId) => (
      apiClient.addAttributeValueToNarrative(actionListId, attributeValueId)),
    onSuccess: () => wrapUpAfterSuccess('Success'),
    errorMsg: 'Error creating action',
  });

  return (
    <ButtonWithModal
      buttonText="Add attribute value to narrative"
      modalTitle="Add attribute value to narrative"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      onClose={() => {
        setAttributeView(null);
      }}
    >
      <AttributeAndValueCrudForm
        attributeId={attributeView?.id}
        attributeFieldValues={attributeView}
        createAttributeCallbacks={createAttributeCallbacks}
        addExistingAttributeCallbacks={addExistingAttributeCallbacks}
        createAttributeValueCallbacks={createAttributeValueCallbacks}
        addExistingAttributeValueCallbacks={addExistingAttributeValueCallbacks}
      />

    </ButtonWithModal>
  );
}

AddAttributeValueToNarrativeButton.propTypes = {
  actionListId: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func.isRequired,
};

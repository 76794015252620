import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import useToast from '../../hooks/useToast';
import Modal from '../../components/Modal';
import { Controls } from '../../components/controls/Controls';
import MetadataCategoryForm from './MetadataCategoryForm';

function MetadataCategoryAdditionButton({ reloadCallback }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [metadataCategoryName, setMetadataCategoryName] = useState('');

  const apiClient = useApiClient();
  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setMetadataCategoryName('');
  };

  const handleAddMetadataCategory = async () => {
    try {
      await apiClient.createMetadataCategory({ name: metadataCategoryName });
      reloadCallback();
      setIsModalOpen(false);
      setMetadataCategoryName('');
      toastSetSuccessNotification('Metadata category added!');
    } catch (err) {
      toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(err));
    }
  };

  return (
    <>
      <Controls.Button
        variant="contained"
        onClick={handleModalOpen}
        style={{ margin: '6px' }}
        text="Create a new metadata category"
      />
      <Modal isOpen={isModalOpen} handleClose={handleModalClose} title="Create a new metadata category">
        <Box>
          <MetadataCategoryForm
            categoryName={metadataCategoryName}
            setCategoryName={setMetadataCategoryName}
            onSubmit={handleAddMetadataCategory}
            submitText="Add"
          />
        </Box>
      </Modal>
    </>
  );
}

MetadataCategoryAdditionButton.propTypes = {
  reloadCallback: PropTypes.func.isRequired,
};

export default MetadataCategoryAdditionButton;

import React from 'react';
import PropTypes from 'prop-types';

import { Divider } from '@mui/material';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';
import MetadataTagPane from './MetadataTagPane';

export default function MetadataTagTreeItem(props) {
  const {
    id,
    categoryId,
    label,
    setFormPane,
    setTreeManagementServices,
    refreshParent,
    onDeleteWarningMessage,
    onDeleteConfirmed,
  } = props;

  const {
    MCTreeItemLabel,
    expand,
    updateLabel,
  } = useMedicalContentTreeItem(label);

  const onSelect = () => {
    setFormPane(
      <>
        <MetadataTagPane
          id={id}
          categoryId={categoryId}
          updateCallback={updateLabel}
          refreshParent={refreshParent}
          label={MCTreeItemLabel}
        />
        <Divider />
      </>,
    );
    setTreeManagementServices({
      onDeleteWarningMessage,
      deleteService: onDeleteConfirmed,
      onSuccess: refreshParent,
    });
  };

  return (
    <MedicalContentTreeItem
      id={id}
      MCTreeItemLabel={MCTreeItemLabel}
      isEndNode
      onSelect={onSelect}
      expand={expand}
    />
  );
}

MetadataTagTreeItem.propTypes = {
  id: PropTypes.number.isRequired,
  categoryId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
  onDeleteWarningMessage: PropTypes.string.isRequired,
  onDeleteConfirmed: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, Chip, Typography, Box,
} from '@mui/material';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import basicServiceHandler from '../../services/basicServiceHandler';
import useToast from '../../hooks/useToast';
import SymptomForm from './SymptomForm';
import MetadataCategoryAndTagForm from './CategoryAndTagForm';

function SymptomPane({ symptomId, updateCallback }) {
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [existingTagsForSymptom, setExistingTagsForSymptom] = useState([]);
  const [initialFieldValues, setInitialFieldValues] = useState(null);

  const apiClient = useApiClient();
  const { toastSetSuccessNotification, toastSetErrorNotification } = useToast();

  // Fetching details
  const fetchSymptomDetails = () => {
    basicServiceHandler(
      () => apiClient.getSymptom(symptomId),
      (response) => setInitialFieldValues(response.data),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  const fetchCategories = () => {
    basicServiceHandler(
      () => apiClient.getAllMetadataCategories(),
      (response) => setCategoriesOptions(response.data),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  const fetchTagsByCategoryId = (categoryId) => {
    basicServiceHandler(
      () => apiClient.getMetadataTagsByCategoryId(categoryId),
      (response) => setTagsOptions(response.data),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  const fetchTagsForSymptom = () => {
    basicServiceHandler(
      () => apiClient.getTagsBySymptomId(symptomId),
      (response) => setExistingTagsForSymptom(response.data),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  // Handling tags
  const handleRemoveTagFromSymptom = (tagId) => {
    basicServiceHandler(
      () => apiClient.removeTagFromSymptom(symptomId, tagId),
      () => {
        setExistingTagsForSymptom(existingTagsForSymptom.filter((tag) => tag.id !== tagId));
        toastSetSuccessNotification('Tag removed successfully!');
      },
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  // Handling metadata
  const handleMetadataSubmit = (values) => {
    const selectedTagToAdd = tagsOptions.find((tag) => tag.id === values.tagId);
    return apiClient.addTagToSymptom(symptomId, selectedTagToAdd.id);
  };

  const handleMetadataSuccess = () => {
    fetchTagsForSymptom();
    toastSetSuccessNotification('Update successful.');
  };

  const handleMetadataFail = (error) => {
    toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error));
  };

  // Handling symptom form submission
  const handleSymptomSubmit = (values) => apiClient.updateSymptom(
    symptomId,
    values.medicalName,
    values.reportName,
    values.layName,
    values.laySubstitutor,
    values.helperText,
  );

  const handleSymptomSuccess = (values) => {
    updateCallback(values.medicalName);
    toastSetSuccessNotification('Update successful.');
  };

  const handleSymptomFail = (error) => {
    toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error));
  };

  useEffect(() => {
    fetchSymptomDetails();
    fetchCategories();
    fetchTagsForSymptom();
  }, [symptomId]);

  return (
    initialFieldValues === null
      ? <CircularProgress />
      : (
        <div>
          <Box marginBottom={7}>
            <SymptomForm
              initialFieldValues={initialFieldValues}
              onSubmit={handleSymptomSubmit}
              onSuccess={handleSymptomSuccess}
              onFail={handleSymptomFail}
            />
          </Box>
          <Box marginBottom={7}>
            <Typography variant="subtitle1">Tags</Typography>
            {existingTagsForSymptom.map((tag) => (
              <Chip
                key={tag.id}
                label={tag.name}
                onDelete={() => handleRemoveTagFromSymptom(tag.id)}
              />
            ))}
          </Box>
          <Typography variant="subtitle1">Add tag</Typography>
          <MetadataCategoryAndTagForm
            categories={categoriesOptions}
            tagsListByCategory={tagsOptions}
            fetchTagsByCategoryId={fetchTagsByCategoryId}
            onSubmit={handleMetadataSubmit}
            onSuccess={handleMetadataSuccess}
            onFail={handleMetadataFail}
          />
        </div>
      )
  );
}

SymptomPane.propTypes = {
  symptomId: PropTypes.number,
  updateCallback: PropTypes.func.isRequired,
};

SymptomPane.defaultProps = {
  symptomId: null,
};

export default SymptomPane;

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Controls } from '../../components/controls/Controls';
import Form from '../../components/useForm';
import ServiceButtonWithToast from '../_common/ServiceButtonWithToast';

function MetadataCategoryForm({
  categoryName,
  setCategoryName,
  onSubmit,
}) {
  const handleChange = ({ target: { value } }) => {
    setCategoryName(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
    setCategoryName('');
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controls.Input
            name="metadataCategoryName"
            label="Metadata Category Name"
            value={categoryName}
            onChange={handleChange}
            placeholder="Enter category name"
          />
        </Grid>
        <Grid item xs={3}>
          <ServiceButtonWithToast
            buttonText="Save"
            service={onSubmit}
            successMsg="Metadata category updated!"
            errorMsg="Unable to update metadata category"
          />
        </Grid>
      </Grid>
    </Form>
  );
}

MetadataCategoryForm.propTypes = {
  categoryName: PropTypes.string.isRequired,
  setCategoryName: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MetadataCategoryForm;

import React from 'react';
import PropTypes from 'prop-types';

import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../../_common/MedicalContentTreeItem';

import { useApiClient } from '../../../context/ApiClientContext';
import TemplateTreeItem from '../Template/TemplateTreeItem';
import TemplateListPane from './TemplateListPane';

export default function TemplateListTreeItem(props) {
  const {
    listId, listUniqueName, refreshParent, setFormPane, setTreeManagementServices,
  } = props;

  const apiClient = useApiClient();

  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    setMCTreeItemChildNodes,
    expand,
    setExpand,
  } = useMedicalContentTreeItem(listUniqueName);

  const fetchChildNodes = async () => {
    const results = await apiClient.getAllTemplatesForList(listId);
    setMCTreeItemChildNodes(results.data.map((result) => (
      <TemplateTreeItem
        key={result.id}
        templateId={result.id}
        templateUniqueName={result.uniqueName}
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
        refreshParent={fetchChildNodes}
      />
    )));
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const onSelect = () => {
    setFormPane(
      <TemplateListPane
        listId={listId}
        listUniqueName={listUniqueName}
        onUpdateSuccess={refreshParent}
        onCreateSuccess={reloadAndExpandNode}
      />,
    );
    setTreeManagementServices({
      onDeleteWarningMessage: `Delete the entire "${listUniqueName}" list? WARNING: ALL TEMPLATES IN THIS LIST WILL BE DELETED.`,
      deleteService: () => apiClient.deleteTemplateList(listId),
      onSuccess: refreshParent,
    });
  };

  return (
    <MedicalContentTreeItem
      id={listId}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChilren="No templates yet..."
    />
  );
}

TemplateListTreeItem.propTypes = {
  listId: PropTypes.number.isRequired,
  listUniqueName: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
};

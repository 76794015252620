import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import MetadataCategoryForm from './MetadataCategoryForm';
import MetadataTagForm from './MetadataTagForm';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import useToast from '../../hooks/useToast';

function MetadataCategoryPane({
  categoryId, label, updateCallback, refreshParent,
}) {
  const apiClient = useApiClient();
  const { toastSetErrorNotification, toastSetSuccessNotification } = useToast();
  const [categoryName, setCategoryName] = useState(label);

  const handleUpdate = async () => {
    try {
      const categoryData = { name: categoryName };
      await apiClient.updateMetadataCategory(categoryId, categoryData);
      updateCallback(categoryData.name);
      toastSetSuccessNotification('Category name updated successfully!');
      refreshParent();
    } catch (error) {
      toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error));
    }
  };

  const handleAddTag = async (metadataTagData) => {
    try {
      const tagData = { ...metadataTagData, categoryId };
      await apiClient.createMetadataTag(tagData);
      toastSetSuccessNotification('Tag added successfully!');
      refreshParent();
    } catch (error) {
      toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error));
    }
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Edit category name</Typography>
        <MetadataCategoryForm
          categoryName={categoryName}
          setCategoryName={setCategoryName}
          onSubmit={handleUpdate}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Add tag to category</Typography>
        <MetadataTagForm
          onSubmit={handleAddTag}
        />
      </Grid>
    </Grid>
  );
}

MetadataCategoryPane.propTypes = {
  categoryId: PropTypes.number.isRequired,
  updateCallback: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  refreshParent: PropTypes.func.isRequired,
};

export default MetadataCategoryPane;

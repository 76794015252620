import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import MetadataTagForm from './MetadataTagForm';
import useToast from '../../hooks/useToast';

function MetadataTagPane({
  id, categoryId, updateCallback, refreshParent,
}) {
  const apiClient = useApiClient();
  const { toastSetErrorNotification, toastSetSuccessNotification } = useToast();

  const handleUpdate = async (tagUpdateData) => {
    try {
      await apiClient.updateMetadataTag(id, { ...tagUpdateData, categoryId });
      updateCallback(tagUpdateData.name);
      toastSetSuccessNotification('Metadata Tag updated successfully!');
      refreshParent();
    } catch (error) {
      toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error));
    }
  };

  return (
    <Grid container spacing={8}>
      <Grid item xs={10}>
        <Typography variant="subtitle1">Edit tag</Typography>
        <MetadataTagForm
          onSubmit={handleUpdate}
        />
      </Grid>
    </Grid>
  );
}

MetadataTagPane.propTypes = {
  id: PropTypes.number.isRequired,
  categoryId: PropTypes.number.isRequired,
  updateCallback: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
};

export default MetadataTagPane;

import React from 'react';

import AttributeCreateButtonWithModal from './createAndEdit/AttributeCreateButtonWithModal';
import AttributeFindButtonWithModal from './findExisting/AttributeFindButtonWithModal';
import { serviceCallbackPropTypes } from '../../../services/basicServiceHandler';

export default function AttributeCreateOrFindForm(props) {
  const {
    createCallbacks,
    addExistingAttributeCallbacks,
  } = props;

  return (
    <>
      <AttributeCreateButtonWithModal
        createCallbacks={createCallbacks}
      />
      <AttributeFindButtonWithModal
        initialContextType="patient"
        addExistingAttributeCallbacks={addExistingAttributeCallbacks}
      />
    </>
  );
}

AttributeCreateOrFindForm.propTypes = {
  createCallbacks: serviceCallbackPropTypes.isRequired,
  addExistingAttributeCallbacks: serviceCallbackPropTypes.isRequired,
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../context/ApiClientContext';
import ConditionEditForm from '../Condition/ConditionEditForm';

export default function ScoreFactorPane(props) {
  const {
    scoreId,
    initialScoreFactor,
    refreshParent,
  } = props;

  const [scoreFactor, setScoreFactor] = useState(initialScoreFactor);

  const apiClient = useApiClient();

  const refreshScoreFactor = async () => {
    const factors = (await apiClient.getFactorsForScore(scoreId)).data;
    const matchingsSoreFactor = factors.find((factor) => factor.id === initialScoreFactor.id);
    setScoreFactor(matchingsSoreFactor);
  };

  useEffect(() => {
    const refresh = async () => {
      await refreshScoreFactor();
    };
    refresh();
  }, [initialScoreFactor.id]);

  const onUpdateCondition = (updatedCondition) => {
    apiClient.updateScoreFactor(scoreFactor.id, { condition: updatedCondition });
  };

  return (
    <ConditionEditForm
      condition={scoreFactor.condition}
      onSubmit={onUpdateCondition}
      onSuccess={() => {
        refreshParent();
        refreshScoreFactor();
      }}
    />
  );
}

ScoreFactorPane.propTypes = {
  scoreId: PropTypes.number.isRequired,
  initialScoreFactor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    condition: PropTypes.shape({
      id: PropTypes.number,
      conditionType: PropTypes.string,
      subconditions: PropTypes.arrayOf(PropTypes.shape({ conditionType: PropTypes.string })),
    }).isRequired,
    points: PropTypes.number.isRequired,
  }).isRequired,
  refreshParent: PropTypes.func.isRequired,
};

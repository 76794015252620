import React from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../context/ApiClientContext';

import MedicationTreeItem from './MedicationTreeItem';
import MedicationAdditionButton from './MedicationAdditionButton';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';

export default function MedicationParentTreeItem(props) {
  const { setFormPane, setTreeManagementServices } = props;

  const apiClient = useApiClient();

  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    expand,
    setExpand,
    setMCTreeItemChildNodes,
  } = useMedicalContentTreeItem('Medications');

  const fetchChildNodes = async () => {
    const results = await apiClient.getAllMedications();
    setMCTreeItemChildNodes(results.data.map((result) => (
      <MedicationTreeItem
        key={`${result.genericName}_${result.id}`}
        id={result.id}
        label={result.genericName}
        actionListId={result.actionListId}
        setFormPane={setFormPane}
        refreshParent={fetchChildNodes}
        setTreeManagementServices={setTreeManagementServices}
        onDeleteWarningMessage={`Do you want to permanently delete the medication "${result.genericName}"?`}
        onDeleteConfirmed={() => apiClient.deleteMedication(result.id)}
      />
    )));
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const onSelect = () => setFormPane(
    <MedicationAdditionButton
      reloadCallback={reloadAndExpandNode}
    />,
  );

  return (
    <MedicalContentTreeItem
      id={0}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChilren="No medications yet..."
    />
  );
}

MedicationParentTreeItem.propTypes = {
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};

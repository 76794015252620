import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AttributeFindForm from './AttributeFindForm';
import ButtonWithModal from '../../../../components/ButtonWithModal';
import { serviceCallbackPropTypes } from '../../../../services/basicServiceHandler';

export default function AttributeFindButtonWithModal(props) {
  const {
    initialContextType, addExistingAttributeCallbacks,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [contextType, setContextType] = useState(initialContextType);
  const [selectedAttributeId, setSelectedAttributeId] = useState(null);

  const callbacks = {
    ...addExistingAttributeCallbacks,
    service: () => addExistingAttributeCallbacks.service(selectedAttributeId),
  };

  return (
    <ButtonWithModal
      buttonText="Select Existing Attribute"
      modalTitle="Select Attribute"
      modalIsOpen={isOpen}
      setModalIsOpen={setIsOpen}
      onClose={() => {
        setContextType(initialContextType);
        setSelectedAttributeId(null);
      }}
    >
      <AttributeFindForm
        selectedContextType={contextType}
        setSelectedContextType={setContextType}
        selectedAttributeId={selectedAttributeId}
        setSelectedAttributeId={setSelectedAttributeId}
        callbacks={callbacks}
      />
    </ButtonWithModal>
  );
}

AttributeFindButtonWithModal.propTypes = {
  initialContextType: PropTypes.oneOf(['patient', 'symptom', 'medication', '']),
  addExistingAttributeCallbacks: serviceCallbackPropTypes.isRequired,
};

AttributeFindButtonWithModal.defaultProps = {
  initialContextType: '',
};

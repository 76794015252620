import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../context/ApiClientContext';

import FixedAnswerForm from './FixedAnswerForm';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';
// eslint-disable-next-line import/no-cycle
import renderActionList from '../Actions/renderActionList';
import createRaiseAndLowerService from '../_common/Utils';

export default function FixedAnswerTreeItem(props) {
  const {
    id,
    actionListId,
    label,
    setFormPane,
    refreshParent,
    setTreeManagementServices,
    index,
    answerList,
    questionId,
  } = props;

  const apiClient = useApiClient();
  const reorderService = apiClient.reorderAnswerList.bind(apiClient);
  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    expand,
    setExpand,
    setMCTreeItemChildNodes,
    updateLabel,
  } = useMedicalContentTreeItem(label);

  const fetchChildNodes = async () => {
    setMCTreeItemChildNodes(
      await renderActionList(
        apiClient,
        actionListId,
        setFormPane,
        setTreeManagementServices,
        fetchChildNodes,
      ),
    );
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const onSuccess = (result) => {
    if (result.data) {
      const newAnswerList = result.data.map((answer) => ({
        id: answer.id,
        text: answer.text,
        'actionListId-selected': answer.actionListIdIfSelected,
      }));
      const prevVal = answerList[index];
      const newIndex = newAnswerList.findIndex((answer) => answer.id === prevVal.id);
      const { raiseService, lowerService } = createRaiseAndLowerService(
        reorderService,
        newAnswerList.map((answer) => answer.id),
        newIndex,
        questionId,
      );
      setTreeManagementServices((prevState) => ({
        ...prevState,
        raiseService,
        lowerService,
      }));
    }
    setFormPane(null);
    refreshParent();
  };

  const onSelect = () => {
    setFormPane(
      <FixedAnswerForm
        fixedAnswerId={id}
        actionListId={actionListId}
        updateTreeItemLabel={updateLabel}
        reloadCallback={reloadAndExpandNode}
      />,
    );
    const { raiseService, lowerService } = createRaiseAndLowerService(
      reorderService,
      answerList.map((answer) => answer.id),
      index,
      questionId,
    );
    setTreeManagementServices({
      onDeleteWarningMessage: `Delete the answer "${label}"?`,
      deleteService: async () => {
        await apiClient.deleteAnswer(id);
        refreshParent();
      },
      raiseService,
      lowerService,
      onSuccess,
    });
  };

  // prefetch children to accurately display chevron
  useEffect(() => {
    fetchChildNodes();
  }, []);

  return (
    <MedicalContentTreeItem
      id={id}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      isEndNode={MCTreeItemChildNodes && MCTreeItemChildNodes.length === 0}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChilren="No actions yet..."
    />
  );
}

FixedAnswerTreeItem.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  actionListId: PropTypes.number.isRequired,
  refreshParent: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  questionId: PropTypes.number.isRequired,
  answerList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })).isRequired,
};

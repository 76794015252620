/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../../context/ApiClientContext';
import ConditionalPane from '../../Conditional/ConditionalPane';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../../_common/MedicalContentTreeItem';
// eslint-disable-next-line import/no-cycle
import renderActionList from '../renderActionList';

export default function ConditionalTreeItem(props) {
  const {
    conditional,
    label,
    actionListIdIfTrue,
    canAddElse,
    setFormPane,
    refreshParent,
    setTreeManagementServices,
    onDeleteWarningMessage,
    deleteService,
    raiseService,
    lowerService,
    onSuccess,
  } = props;

  const apiClient = useApiClient();

  const {
    MCTreeItemChildNodes,
    setMCTreeItemChildNodes,
    expand,
    setExpand,
  } = useMedicalContentTreeItem(label);

  const fetchChildNodes = async () => {
    setMCTreeItemChildNodes(await renderActionList(
      apiClient,
      actionListIdIfTrue,
      setFormPane,
      setTreeManagementServices,
      fetchChildNodes,
    ));
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const onSelect = () => {
    setFormPane(
      <ConditionalPane
        initialConditional={conditional}
        canAddElse={canAddElse}
        actionListIdIfTrue={actionListIdIfTrue}
        reloadCallback={reloadAndExpandNode}
        refreshParent={refreshParent}
      />,
    );
    setTreeManagementServices({
      onDeleteWarningMessage,
      deleteService,
      raiseService,
      lowerService,
      onSuccess,
    });
  };

  return (
    <MedicalContentTreeItem
      id={conditional.id}
      MCTreeItemLabel={label}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChilren="No actions yet..."
    />
  );
}

ConditionalTreeItem.propTypes = {
  conditional:
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      condition: PropTypes.shape({
        id: PropTypes.number,
        conditionType: PropTypes.string,
        subconditions: PropTypes.arrayOf(PropTypes.shape({ conditionType: PropTypes.string })),
      }),
    }).isRequired,
  setFormPane: PropTypes.func.isRequired,
  label: PropTypes.string,
  actionListIdIfTrue: PropTypes.number.isRequired,
  canAddElse: PropTypes.bool.isRequired,
  refreshParent: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
  onDeleteWarningMessage: PropTypes.string.isRequired,
  deleteService: PropTypes.func,
  raiseService: PropTypes.func,
  lowerService: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
};

ConditionalTreeItem.defaultProps = {
  label: 'Set Conditional',
  deleteService: null,
  raiseService: null,
  lowerService: null,
};

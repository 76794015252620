import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../../../context/ApiClientContext';
import useToast from '../../../../hooks/useToast';
import basicServiceHandler from '../../../../services/basicServiceHandler';

import Dropdown from '../../../../components/controls/Dropdown';

export default function AttributeSelector({
  selectedContextType,
  setSelectedContextType,
  selectedAttributeId,
  setSelectedAttributeId,
  excludeAttributesWithoutValues,
}) {
  const [attributeOptions, setAttributeOptions] = useState([]);

  const apiClient = useApiClient();

  const {
    toastSetErrorNotification,
  } = useToast();

  const attributeSearchServiceSuccessCB = (result) => {
    if (result.data.length) setAttributeOptions(result.data);
  };

  useEffect(
    () => {
      if (selectedContextType) {
        basicServiceHandler(
          () => apiClient.attributeSearch(selectedContextType, excludeAttributesWithoutValues),
          attributeSearchServiceSuccessCB,
          (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
          () => {},
        );
      }
    },
    [selectedContextType],
  );

  return (
    <>
      <Dropdown
        value={selectedContextType}
        name="selectedContextType"
        onChange={(e) => setSelectedContextType(e.target.value)}
        label="Select a context type"
        options={[{ id: 'patient', name: 'Patient' }, { id: 'symptom', name: 'Symptom' }, { id: 'medication', name: 'Medication' }]}
      />
      {selectedContextType && (
        <Dropdown
          value={selectedAttributeId}
          name="selectedAttribute"
          onChange={(e) => setSelectedAttributeId(e.target.value)}
          label="Select an Attribute"
          options={attributeOptions.map((attr) => ({ id: attr.id, name: attr.uniqueName }))}
        />
      )}
    </>
  );
}

AttributeSelector.propTypes = {
  selectedContextType: PropTypes.oneOf(['patient', 'symptom', '']),
  setSelectedContextType: PropTypes.func.isRequired,
  selectedAttributeId: PropTypes.number,
  setSelectedAttributeId: PropTypes.func.isRequired,
  excludeAttributesWithoutValues: PropTypes.bool,
};

AttributeSelector.defaultProps = {
  selectedContextType: '',
  excludeAttributesWithoutValues: false,
  selectedAttributeId: '',
};

import React from 'react';
// eslint-disable-next-line import/no-cycle
import QuestionTreeItem from './AskQuestion/QuestionTreeItem';
// eslint-disable-next-line import/no-cycle
import ModuleTreeItem from '../Module/ModuleTreeItem';
// eslint-disable-next-line import/no-cycle
import ConditionalTreeItem from './DoConditional/ConditionalTreeItem';
import PlainTextActionTreeItem from './PlainTextActions/PlainTextActionTreeItem';
import AttributeValueToNarrativeTreeItem from './AddAttributeValueToNarrative/AttributeValueToNarrativeTreeItem';
import generateConditionLabel from '../Condition/generateConditionLabel';
import createRaiseAndLowerService from '../_common/Utils';
import BasicEndNodeTreeItem from '../_common/BasicEndNodeTreeItem';

/**
 * Converts an array of ActionViews to an array of TreeItems based on the actionType.
 */
export default async function renderActionList(
  axiosServices,
  actionListId,
  setFormPane,
  setTreeManagementServices,
  refreshParent,
) {
  const actionViews = (await axiosServices.getActionList(actionListId)).data;
  const orderedActionIds = actionViews.map((action) => action.actionId);
  return actionViews.map((result, i) => {
    const reorderService = axiosServices.reorderActionList.bind(axiosServices);
    const { raiseService, lowerService } = createRaiseAndLowerService(
      reorderService,
      orderedActionIds,
      i,
      actionListId,
    );

    const onSuccess = (res) => {
      if (res.data) {
        const newOrderedActionIds = res.data.map((action) => action.actionId);
        const prevVal = orderedActionIds[i];
        const newIndex = newOrderedActionIds.indexOf(prevVal);
        const {
          raiseService: newRaiseService,
          lowerService: newLowerService,
        } = createRaiseAndLowerService(
          reorderService,
          newOrderedActionIds,
          newIndex,
          actionListId,
        );
        setTreeManagementServices((prevState) => ({
          ...prevState,
          raiseService: newRaiseService,
          lowerService: newLowerService,
        }));
      }
      setFormPane(null);
      refreshParent();
    };

    switch (result.actionType) {
      case 'askQuestion':
        return (
          <QuestionTreeItem
            key={`${result.questionData.text}_${result.questionData.id}`}
            questionId={result.questionData.id}
            label={result.questionData.text}
            questionType={result.questionData.questionType}
            askQuestionActionId={result.actionId}
            setFormPane={setFormPane}
            setTreeManagementServices={setTreeManagementServices}
            onDeleteWarningMessage={`Permanently delete the question "${result.questionData.text}"?`}
            deleteService={async () => {
              await axiosServices.deleteQuestion(result.questionData.id);
              setTreeManagementServices({});
              refreshParent();
            }}
            raiseService={raiseService}
            lowerService={lowerService}
            onRaiseLowerSuccess={onSuccess}
            refreshParent={refreshParent}
          />
        );
      case 'doModule': {
        const { actionId, moduleData } = result;
        const { id, uniqueName } = moduleData;
        return (
          <ModuleTreeItem
            key={`${uniqueName}_${id}`}
            id={id}
            actionListId={moduleData.actionListId}
            label={`Module: ${uniqueName}`}
            setFormPane={setFormPane}
            setTreeManagementServices={setTreeManagementServices}
            refreshParent={refreshParent}
            onDeleteWarningMessage={`Remove the "${uniqueName}" module from this sequence of actions?`}
            deleteService={async () => {
              await axiosServices.removeActionFromActionList(actionListId, actionId);
              setTreeManagementServices({});
              refreshParent();
            }}
            raiseService={raiseService}
            lowerService={lowerService}
            onSuccess={onSuccess}
          />
        );
      }
      case 'doConditional': {
        const { conditionalData } = result;
        return (
          <div key={`conditionals_${conditionalData.id}`}>
            <ConditionalTreeItem
              key={`conditional_${conditionalData.id}`}
              id={conditionalData.id}
              conditional={conditionalData}
              actionListIdIfTrue={conditionalData.actionListId_true}
              canAddElse={conditionalData.actionListId_false === null}
              setFormPane={setFormPane}
              setTreeManagementServices={setTreeManagementServices}
              label={`IF ${generateConditionLabel(result.conditionalData.condition)}:`}
              refreshParent={refreshParent}
              onDeleteWarningMessage={
              `Permanently delete the conditional "${`IF ${generateConditionLabel(result.conditionalData.condition)}:`}"? This will delete the descendent actions as well.`
            }
              deleteService={async () => {
                await axiosServices.deleteConditional(result.conditionalData.id);
                setTreeManagementServices({});
                refreshParent();
              }}
              raiseService={raiseService}
              lowerService={lowerService}
              onSuccess={onSuccess}
            />
            {conditionalData.actionListId_false
            && (
              <ConditionalTreeItem
                key={`else_conditional_${conditionalData.id}`}
                id={conditionalData.id}
                conditional={conditionalData}
                actionListIdIfTrue={conditionalData.actionListId_false}
                canAddElse={false}
                setFormPane={setFormPane}
                setTreeManagementServices={setTreeManagementServices}
                label="ELSE:"
                refreshParent={refreshParent}
                onDeleteWarningMessage={
                  `Remove the ELSE from "${`${generateConditionLabel(result.conditionalData.condition)}`}"? This will delete the descendent actions as well.`
                }
                deleteService={async () => {
                  await axiosServices.updateConditional(
                    result.conditionalData.id,
                    { hasElse: false },
                  );
                  setTreeManagementServices({});
                  refreshParent();
                }}
                raiseService={raiseService}
                lowerService={lowerService}
                onSuccess={onSuccess}
              />
            )}
          </div>
        );
      }
      case 'addDxHx': {
        const { actionId, dxhxData } = result;
        const { id, name } = dxhxData;
        return (
          <BasicEndNodeTreeItem
            key={`${name}_${id}`}
            id={id}
            label={`Include DxHx: ${name}`}
            setFormPane={setFormPane}
            setTreeManagementServices={setTreeManagementServices}
            onDeleteWarningMessage={`Remove the "${name}" Dxhx from this sequence of actions?`}
            deleteService={async () => {
              await axiosServices.removeActionFromActionList(actionListId, actionId);
              setTreeManagementServices({});
              refreshParent();
            }}
            raiseService={raiseService}
            lowerService={lowerService}
            onSuccess={onSuccess}
          />
        );
      }
      case 'addSxToNarrative': {
        const { actionId, symptomData } = result;
        const { id, medicalName } = symptomData;
        return (
          <BasicEndNodeTreeItem
            key={`${medicalName}_${id}`}
            id={id}
            label={`Add symptom: ${medicalName}`}
            setFormPane={setFormPane}
            setTreeManagementServices={setTreeManagementServices}
            onDeleteWarningMessage={`Remove the "${medicalName}" symptom from this sequence of actions?`}
            deleteService={async () => {
              await axiosServices.removeActionFromActionList(actionListId, actionId);
              setTreeManagementServices({});
              refreshParent();
            }}
            raiseService={raiseService}
            lowerService={lowerService}
            onSuccess={onSuccess}
          />
        );
      }
      case 'addDeniedSxToNarrative': {
        const { actionId, symptomData } = result;
        const { id, medicalName } = symptomData;
        return (
          <BasicEndNodeTreeItem
            key={`denied_${medicalName}_${id}`}
            id={id}
            label={`Add denied symptom: ${medicalName}`}
            setFormPane={setFormPane}
            setTreeManagementServices={setTreeManagementServices}
            onDeleteWarningMessage={`No longer deny "${medicalName}"?`}
            deleteService={async () => {
              await axiosServices.removeActionFromActionList(actionListId, actionId);
              setTreeManagementServices({});
              refreshParent();
            }}
            raiseService={raiseService}
            lowerService={lowerService}
            onSuccess={onSuccess}
          />
        );
      }
      case 'addMedicationToNarrative': {
        const { actionId, medicationData } = result;
        const { id, genericName } = medicationData;
        return (
          <BasicEndNodeTreeItem
            key={`${genericName}_${id}`}
            id={id}
            label={`Add medication: ${genericName}`}
            setFormPane={setFormPane}
            setTreeManagementServices={setTreeManagementServices}
            onDeleteWarningMessage={`Remove adding "${genericName}" from this sequence of actions?`}
            deleteService={async () => {
              await axiosServices.removeActionFromActionList(actionListId, actionId);
              setTreeManagementServices({});
              refreshParent();
            }}
            raiseService={raiseService}
            lowerService={lowerService}
            onSuccess={onSuccess}
          />
        );
      }
      case 'setFixedAttributeValue': {
        const { actionId, attributeValueData, attributeName } = result;
        const {
          id, attributeId, c2iName,
        } = attributeValueData;
        return (
          <AttributeValueToNarrativeTreeItem
            key={`add_att_val_${id}`}
            id={id}
            attributeId={attributeId}
            label={`Set [${attributeName}]=${c2iName}`}
            attributeValueData={attributeValueData}
            setFormPane={setFormPane}
            setTreeManagementServices={setTreeManagementServices}
            onDeleteWarningMessage={`Remove setting [${attributeName}]=${c2iName} from this sequence of actions?`}
            deleteService={async () => {
              await axiosServices.removeActionFromActionList(actionListId, actionId);
              setTreeManagementServices({});
              refreshParent();
            }}
            raiseService={raiseService}
            lowerService={lowerService}
            onSuccess={onSuccess}
          />
        );
      }
      case 'addScoreToReport': {
        const { actionId, scoreData, reportSection } = result;
        const { id, uniqueName } = scoreData;
        return (
          <BasicEndNodeTreeItem
            key={`addScoreAction_${id}`}
            id={id}
            label={`Add ${uniqueName} score to report (${reportSection} section)`}
            setFormPane={setFormPane}
            setTreeManagementServices={setTreeManagementServices}
            onDeleteWarningMessage={`Remove the "${uniqueName}" score from the report?`}
            deleteService={async () => {
              await axiosServices.removeActionFromActionList(actionListId, actionId);
              setTreeManagementServices({});
              refreshParent();
            }}
            raiseService={raiseService}
            lowerService={lowerService}
            onSuccess={onSuccess}
          />
        );
      }
      case 'offerTemplates': {
        const { actionId, templateListName, templateCategory } = result;
        return (
          <BasicEndNodeTreeItem
            key={actionId}
            id={actionId}
            label={`Offer ${templateCategory} templates with report: ${templateListName}`}
            setFormPane={setFormPane}
            setTreeManagementServices={setTreeManagementServices}
            onDeleteWarningMessage={`No longer offer "${templateListName}" templates here?`}
            deleteService={async () => {
              await axiosServices.removeActionFromActionList(actionListId, actionId);
              setTreeManagementServices({});
              refreshParent();
            }}
            raiseService={raiseService}
            lowerService={lowerService}
            onSuccess={onSuccess}
          />
        );
      }
      default: {
        const { actionId, actionType } = result;
        return (
          <PlainTextActionTreeItem
            key={`${actionType}_${actionId}`}
            id={actionId}
            actionType={actionType}
            setFormPane={setFormPane}
            setTreeManagementServices={setTreeManagementServices}
          />
        );
      }
    }
  });
}

import React from 'react';

import { Grid } from '@mui/material';

import Form from '../../../components/useForm';

import AttributeFormContent, { attributeFieldValuesPropType } from './AttributeFormContent';
import AttributeEditButtonWithModal from './createAndEdit/AttributeEditButtonWithModal';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';
import AttributeCreateOrFindForm from './AttributeCreateOrFindForm';
import { serviceCallbackPropTypes } from '../../../services/basicServiceHandler';

export default function AttributeCrudForm(props) {
  const {
    attributeFieldValues,
    createCallbacks,
    addExistingAttributeCallbacks,
    updateCallbacks,
    removeCallbacks,
  } = props;

  return (
    attributeFieldValues ? (
      <>
        <Form>
          <AttributeFormContent
            values={attributeFieldValues}
            controlsDisabled
          />
        </Form>
        <Grid container justifyContent="flex-end">
          {updateCallbacks && (
            <AttributeEditButtonWithModal
              attributeFieldValues={attributeFieldValues}
              updateCallbacks={updateCallbacks}
            />
          )}
          {removeCallbacks && (
            <ServiceButtonWithToast
              buttonText="Remove Attribute"
              service={removeCallbacks.service}
              onSuccess={removeCallbacks.onSuccess}
              errorMsg={removeCallbacks.errorMsg}
            />
          )}
        </Grid>
      </>
    ) : (
      <AttributeCreateOrFindForm
        createCallbacks={createCallbacks}
        addExistingAttributeCallbacks={addExistingAttributeCallbacks}
      />
    )
  );
}

AttributeCrudForm.propTypes = {
  attributeFieldValues: attributeFieldValuesPropType,
  createCallbacks: serviceCallbackPropTypes.isRequired,
  addExistingAttributeCallbacks: serviceCallbackPropTypes.isRequired,
  updateCallbacks: serviceCallbackPropTypes,
  removeCallbacks: serviceCallbackPropTypes,
};

AttributeCrudForm.defaultProps = {
  attributeFieldValues: null,
  updateCallbacks: null,
  removeCallbacks: null,
};

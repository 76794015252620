import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Form from '../../components/useForm';
import { Controls } from '../../components/controls/Controls';
import ServiceButtonWithToast from '../_common/ServiceButtonWithToast';

function MetadataTagForm({
  onSubmit,
}) {
  const [tagName, setTagName] = useState('');

  const handleSubmit = () => {
    onSubmit({ name: tagName });
    setTagName('');
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controls.Input
            name="tagName"
            label="Tag Name"
            value={tagName}
            onChange={(e) => setTagName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <ServiceButtonWithToast
            buttonText="Save"
            service={() => onSubmit({ name: tagName })}
            successMsg="Metadata tag updated!"
            errorMsg="Unable to update metadata tag"
          />
        </Grid>
      </Grid>
    </Form>
  );
}

MetadataTagForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default MetadataTagForm;

import React from 'react';
import PropTypes from 'prop-types';

import MedicalContentTreeItem from '../../_common/MedicalContentTreeItem';

function actionListTypeToLabel(actionType) {
  switch (actionType) {
    case 'elicitSxsAcrossDxhxs':
      return '*Elicit Sxs Across Dxhx';
    case 'doSxActionsAcrossDxhxs':
      return '*Do Actions for All Endorsed Sxs';
    case 'doPtFactorsAcrossDxhxs':
      return '*Do Pt Factor Actions for All Dxhxs';
    case 'setSexMale':
      return '*Set Sex to Male';
    case 'setSexFemale':
      return '*Set Sex to Female';
    default:
      return `*${actionType}`;
  }
}

export default function PlainTextActionTreeItem(props) {
  const {
    actionType,
    setFormPane,
    setTreeManagementServices,
  } = props;

  const label = actionListTypeToLabel(actionType);

  const onSelect = () => {
    setFormPane(null);
    setTreeManagementServices({
      onDeleteWarningMessage: '',
      deleteService: null,
      raiseService: null,
      lowerService: null,
      onSuccess: null,
    });
  };

  return (
    <MedicalContentTreeItem
      id={0}
      MCTreeItemLabel={label}
      isEndNode
      onSelect={onSelect}
    />
  );
}

PlainTextActionTreeItem.propTypes = {
  actionType: PropTypes.oneOf(['addDxHx', 'elicitSxsAcrossDxhxs', 'doSxActionsAcrossDxhxs', 'doPtFactorsAcrossDxhxs']).isRequired,
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};

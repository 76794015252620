import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

import Handle from './components/Handle';
import TreeManagementPane from './fhx_components/_common/TreeManagementPane';

import DxHxParentTreeItem from './fhx_components/DxHx/DxHxParentTreeItem';
import ModulesParentTreeItem from './fhx_components/Module/ModulesParentTreeItem';
import MedicationParentTreeItem from './fhx_components/Medication/MedicationParentTreeItem';
import MedMainClassParentTreeItem from './fhx_components/MedMainClass/MedMainClassParentTreeItem';
import SymptomParentTreeItem from './fhx_components/Symptom/SymptomParentTreeItem';
import ScoreParentTreeItem from './fhx_components/Score/ScoreParentTreeItem';
import MetadataCategoryParentTreeItem from './fhx_components/Metadata/MetadataCategoryParentTreeItem';
import TranslationParentTreeItem from './fhx_components/Translation/TranslationParentTreeItem';
import ReasonForVisitParentTreeItem from './fhx_components/ReasonForVist/ReasonForVisitParentTreeItem';
import TemplateCategoryParentTreeItem from './fhx_components/Template/TemplateCategoryParentTreeItem';
import IntakePlanParentTreeItem from './fhx_components/IntakePlan/IntakePlanParentTreeItem';

function Home() {
  const [treeManagementServices, setTreeManagementServices] = useState({
    onDeleteWarningMessage: '',
    deleteService: null,
    raiseService: null,
    lowerService: null,
    onSuccess: null,
  });

  const [formPaneContents, setFormPane] = useState(null);

  const initialWidth = window.innerWidth * 0.5;
  const [leftWidth, setLeftWidth] = useState(initialWidth);
  const [rightWidth, setRightWidth] = useState(initialWidth);

  const HandleResize = (event, {
    size,
  }) => {
    setLeftWidth(size.width);
    setRightWidth(window.innerWidth - size.width - 1);
  };

  return (
    <div style={{ display: 'flex', height: '98vh', overflow: 'auto' }}>
      <ResizableBox
        width={leftWidth}
        axis="x"
        onResize={HandleResize}
        resizeHandles={['e']}
        handle={<Handle />}
      >
        <Grid item style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Grid container justifyContent="center">
            <TreeManagementPane
              treeManagementServices={treeManagementServices}
              setFormPane={setFormPane}
            />
          </Grid>
          <Box
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              flexGrow: 1,
              paddingBottom: '10px',
            }}
          >
            <IntakePlanParentTreeItem
              setFormPane={setFormPane}
              setTreeManagementServices={setTreeManagementServices}
            />
            <ModulesParentTreeItem
              setFormPane={setFormPane}
              setTreeManagementServices={setTreeManagementServices}
            />
            <DxHxParentTreeItem
              setFormPane={setFormPane}
              setTreeManagementServices={setTreeManagementServices}
            />
            <MedicationParentTreeItem
              setFormPane={setFormPane}
              setTreeManagementServices={setTreeManagementServices}
            />
            <MedMainClassParentTreeItem
              setFormPane={setFormPane}
              setTreeManagementServices={setTreeManagementServices}
            />
            <SymptomParentTreeItem
              setFormPane={setFormPane}
              setTreeManagementServices={setTreeManagementServices}
            />
            <ScoreParentTreeItem
              setFormPane={setFormPane}
              setTreeManagementServices={setTreeManagementServices}
            />
            <MetadataCategoryParentTreeItem
              setFormPane={setFormPane}
              setTreeManagementServices={setTreeManagementServices}
            />
            <ReasonForVisitParentTreeItem
              setFormPane={setFormPane}
              setTreeManagementServices={setTreeManagementServices}
            />
            <TemplateCategoryParentTreeItem
              setFormPane={setFormPane}
              setTreeManagementServices={setTreeManagementServices}
            />
            <TranslationParentTreeItem setFormPane={setFormPane} />
          </Box>
        </Grid>
      </ResizableBox>
      <div
        style={{
          flexGrow: 1,
          overflow: 'auto',
          width: rightWidth,
          marginLeft: '10px',
        }}
      >
        <Grid item>
          {formPaneContents}
        </Grid>
      </div>
    </div>
  );
}

export default Home;

import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from '../../components/useForm';

import Dropdown from '../../components/controls/Dropdown';
import AgeRangeSubForm from './ConditionSubforms/AgeRangeSubForm';
import SymptomEndorsedSubForm from './ConditionSubforms/SymptomEndorsedSubForm';
import FixedAttributeValueEndorsedSubForm from './ConditionSubforms/FixedAttributeValueEndorsedSubForm';
import SexConditionSubForm from './ConditionSubforms/SexConditionSubForm';
import ScoreInRangeSubForm from './ConditionSubforms/ScoreInRangeSubForm';
import AnyOrAllOfConditionCreationSubForm from './ConditionSubforms/AnyOrAllOfConditionSubForm';
import TakingMedicationSubForm from './ConditionSubforms/TakingMedicationSubForm';

export default function ConditionCreateSubForm(props) {
  const initialFieldValues = {
    conditionType: '',
  };

  const {
    onSubmit,
    onSuccess,
    successMsg,
  } = props;

  const {
    values,
    handleInputChange,
  } = useForm(initialFieldValues);

  const {
    conditionType,
  } = values;

  return (
    <>
      <Dropdown
        value={conditionType}
        name="conditionType"
        onChange={handleInputChange}
        label="Condition Type"
        options={[
          { id: 'fixedAttributeValueEndorsed', name: 'Check if an attribute value is set' },
          { id: 'ageInRange', name: 'Check if the patient\'s age is in a range' },
          { id: 'scoreInRange', name: 'Check if a Score is in a range' },
          { id: 'symptomEndorsed', name: 'Check if the patient has endorsed a symptom' },
          { id: 'takingMedication', name: 'Check if the patient is taking a medication' },
          { id: 'sexCondition', name: 'Check the patient\'s sex' },
          { id: 'anyOf', name: 'OR (Check if any subcondition is true)' },
          { id: 'allOf', name: 'AND (Check if all subconditions are true)' },
        ]}
      />
      {conditionType === 'ageInRange' && <AgeRangeSubForm onSubmit={onSubmit} onSuccess={onSuccess} successMsg={successMsg} />}
      {conditionType === 'scoreInRange' && <ScoreInRangeSubForm onSubmit={onSubmit} onSuccess={onSuccess} successMsg={successMsg} />}
      {conditionType === 'fixedAttributeValueEndorsed' && <FixedAttributeValueEndorsedSubForm onSubmit={onSubmit} onSuccess={onSuccess} successMsg={successMsg} />}
      {conditionType === 'symptomEndorsed' && <SymptomEndorsedSubForm onSubmit={onSubmit} onSuccess={onSuccess} successMsg={successMsg} />}
      {conditionType === 'takingMedication' && <TakingMedicationSubForm onSubmit={onSubmit} onSuccess={onSuccess} successMsg={successMsg} />}
      {conditionType === 'sexCondition' && <SexConditionSubForm onSubmit={onSubmit} onSuccess={onSuccess} successMsg={successMsg} />}
      {conditionType === 'anyOf' && <AnyOrAllOfConditionCreationSubForm conditionType="anyOf" onSubmit={onSubmit} onSuccess={onSuccess} successMsg={successMsg} />}
      {conditionType === 'allOf' && <AnyOrAllOfConditionCreationSubForm conditionType="allOf" onSubmit={onSubmit} onSuccess={onSuccess} successMsg={successMsg} />}

    </>
  );
}

ConditionCreateSubForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  successMsg: PropTypes.string,
};

ConditionCreateSubForm.defaultProps = {
  onSuccess: undefined,
  successMsg: undefined,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { serviceCallbackPropTypes } from '../../../../services/basicServiceHandler';

import Form from '../../../../components/useForm';
import ServiceButtonWithToast from '../../../_common/ServiceButtonWithToast';
import AttributeSelector from './AttributeSelector';

export default function AttributeFindForm({
  selectedContextType,
  setSelectedContextType,
  selectedAttributeId,
  setSelectedAttributeId,
  excludeAttributesWithoutValues,
  callbacks,
}) {
  const {
    service, onSuccess, successMsg, errorMsg,
  } = callbacks;

  return (
    <Form>
      <Grid container>
        <Grid item xs={6}>
          <AttributeSelector
            selectedContextType={selectedContextType}
            setSelectedContextType={setSelectedContextType}
            selectedAttributeId={selectedAttributeId}
            setSelectedAttributeId={setSelectedAttributeId}
            excludeAttributesWithoutValues={excludeAttributesWithoutValues}
          />
          <Grid container justifyContent="flex-end">
            <ServiceButtonWithToast
              buttonText="Select"
              service={service}
              onSuccess={onSuccess}
              successMsg={successMsg}
              errorMsg={errorMsg}
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

AttributeFindForm.propTypes = {
  selectedContextType: PropTypes.oneOf(['patient', 'symptom', '']),
  setSelectedContextType: PropTypes.func.isRequired,
  selectedAttributeId: PropTypes.number,
  setSelectedAttributeId: PropTypes.func.isRequired,
  excludeAttributesWithoutValues: PropTypes.bool,
  callbacks: serviceCallbackPropTypes.isRequired,
};

AttributeFindForm.defaultProps = {
  selectedContextType: '',
  excludeAttributesWithoutValues: false,
  selectedAttributeId: '',
};

import React from 'react';
import { Provider as LoggingProvider, ErrorBoundary } from '@rollbar/react';
import {
  Routes, Route,
} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ApiClientProvider } from './context/ApiClientContext';
import FhxLogin from './login/FhxLogin';
import Home from './Home';
import Unauthorized from './components/pages/Unauthorized';
import RequireAuth from './components/pages/RequireAuth';
import PersistLogin from './login/PersistLogin';
import FhxMfaSignUp from './login/FhxMfaSignUp';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ENV,
  enabled: ['development', 'staging', 'production', 'translation-test'].includes(process.env.REACT_APP_ENV),
};

function App() {
  const theme = createTheme();
  return (
    <LoggingProvider config={rollbarConfig}>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <ApiClientProvider>
            <Routes>
              {/* public */}
              <Route path="/login" element={<FhxLogin img="https://source.unsplash.com/random" />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="/multi-factor-signup" element={<FhxMfaSignUp img="https://source.unsplash.com/random" />} />

              {/* private */}
              <Route element={<PersistLogin />}>
                <Route element={<RequireAuth allowedPermissions={{ c2i: ['Read'] }} />}>
                  <Route path="/home" element={<Home />} exact />
                </Route>
              </Route>

              {/* fallback */}
              <Route path="*" element={<FhxLogin img="https://source.unsplash.com/random" />} />
            </Routes>
          </ApiClientProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </LoggingProvider>
  );
}

export default App;

import React from 'react';
import PropTypes from 'prop-types';

import ReasonForVisitTreeItem from './ReasonForVisitTreeItem';
import ReasonForVisitAdditionButton from './ReasonForVisitAdditionButton';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';

import { useApiClient } from '../../context/ApiClientContext';

export default function ReasonForVisitParentTreeItem(props) {
  const { setFormPane, setTreeManagementServices } = props;

  const apiClient = useApiClient();

  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    expand,
    setMCTreeItemChildNodes,
  } = useMedicalContentTreeItem('Reason For Visit');

  const fetchChildNodes = async () => {
    const results = await apiClient.getAllReasonsForVisit();
    setMCTreeItemChildNodes(results.data.map((result) => (
      <ReasonForVisitTreeItem
        key={`${result.name}_${result.id}`}
        reasonForVisit={result}
        actionListId={result.actionListId}
        label={result.c2iName}
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
        refreshParent={fetchChildNodes}
        onDeleteWarningMessage={`Do you want to permanently delete the reason for visit "${result.name}"?`}
        onDeleteConfirmed={() => apiClient.deleteReasonForVisit(result.id)}
      />
    )));
  };

  const onSelect = () => {
    setFormPane(
      <ReasonForVisitAdditionButton
        refreshParent={fetchChildNodes}
      />,
    );
    setTreeManagementServices({});
  };

  return (
    <MedicalContentTreeItem
      id={0}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      expand={expand}
      onSelect={onSelect}
      textToDisplayIfNoChilren="No reason for visits yet..."
    />
  );
}

ReasonForVisitParentTreeItem.propTypes = {
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Avatar, CssBaseline, FormControlLabel, Checkbox, Paper, Box, Grid, Typography,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { login, mfaVerify } from '../services/auth/authServices';
import { useForm } from '../components/useForm';
import { Controls } from '../components/controls/Controls';
import useToast from '../hooks/useToast';
import useAuth from '../hooks/useAuth';
import { convertAxiosErrorToUserFacingMessage } from '../context/ApiClientContext';

const initialFieldValues = {
  username: '',
  password: '',
  mfaCode: '',
};

export default function FhxLogin(props) {
  const { setAuth, persist, setPersist } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/home';
  const [mfaStep, setMfaStep] = useState('preMfaLogin');

  const [signInLoading, setSignInLoading] = useState(false);
  const [mfaPhoneNumber, setMfaPhoneNumber] = useState('');
  const {
    toastSetErrorNotification,
  } = useToast();
  const { img } = props;
  const [imgState, setImgState] = useState(img);
  const theme = createTheme();

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFieldValues);

  const navigateToMfaSignup = (username, signUpToken) => {
    navigate('/multi-factor-signup', {
      state: { username, signUpToken },
    });
  };

  const updateAuthAndNavigate = (response) => {
    const { accessToken } = response.data;
    setAuth({ accessToken });
    navigate(from);
  };

  const handleErrors = (error) => {
    let errorMsg = {};
    const hasResponse = Boolean(error.response);
    const isInvalidVerificationCode = hasResponse && error.response.data.message === 'Invalid verification code';
    const isUnauthorizedStatus = hasResponse && (error.response.status === 403
      || error.response.status === 401);
    if (isInvalidVerificationCode) {
      toastSetErrorNotification('Invalid verification code');
    } else if (isUnauthorizedStatus) {
      errorMsg = mfaStep === 'preMfaLogin'
        ? { username: 'Invalid username/password', password: 'Invalid username/password' }
        : { mfaCode: 'Invalid MFA code' };
    } else if (hasResponse) {
      errorMsg = convertAxiosErrorToUserFacingMessage(error) || 'Authentication error';
    } else {
      toastSetErrorNotification(error.message);
    }
    setErrors({ ...errorMsg });
  };

  const handleLogin = async () => {
    try {
      const response = await login(values.username, values.password);
      if (response.status === 202) {
        if (response.data.info.status === 'MfaPending') {
          values.token = response.data.info.token;
          const maskedPhoneNumber = response.data.info.phoneNumber.replace(/\d(?=\d{4})/g, '*');
          setMfaPhoneNumber(maskedPhoneNumber);
          setMfaStep('verifyCode');
        } else if (response.data.info.status === 'MfaNotSet') {
          navigateToMfaSignup(values.username, response.data.info.token);
        }
      }
      if (response.status === 200) {
        // Successful login if mfa not required
        updateAuthAndNavigate(response);
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleMfaVerify = async () => {
    try {
      const response = await mfaVerify(values.username, values.mfaCode, values.token);
      if (response.status === 200) {
        updateAuthAndNavigate(response);
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSignInLoading(true);

    if (mfaStep === 'preMfaLogin') {
      await handleLogin();
    } else {
      await handleMfaVerify();
    }
    setSignInLoading(false);
  };

  const togglePersist = (e) => {
    setPersist(e.target.checked);
  };

  useEffect(() => {
    setImgState(img);
    localStorage.setItem('persist', persist);
  }, [img, persist]);

  useEffect(() => {
  }, [mfaStep]);

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${imgState})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            {(
              <Box component="form" noValidate={false} onSubmit={handleSubmit} sx={{ mt: 1 }}>
                {mfaStep === 'preMfaLogin' && (
                  <>
                    <Controls.Input
                      fullWidth
                      label="Username"
                      name="username"
                      value={values.username}
                      error={errors.username}
                      onChange={handleInputChange}
                      sx={{ margin: '10px 0px' }}
                    />
                    <Controls.Input
                      fullWidth
                      label="Password"
                      name="password"
                      value={values.password}
                      error={errors.password}
                      type="password"
                      onChange={handleInputChange}
                      sx={{ margin: '10px 0px' }}
                    />
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                      onChange={togglePersist} // Assuming you have a function to handle this
                    />
                    <Controls.Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      text="Sign In"
                      loading={signInLoading}
                    />
                  </>
                )}
                {mfaStep === 'verifyCode' && (
                  <>
                    <Box sx={{ mb: 2 }}>
                      {
                        `MFA verification. Please enter the code sent to your phone number: ${mfaPhoneNumber}`
                      }
                    </Box>
                    <Controls.Input
                      fullWidth
                      label="Verification Code"
                      name="mfaCode"
                      value={values.mfaCode}
                      error={errors.mfaCode}
                      onChange={handleInputChange}
                      sx={{ margin: '10px 0px' }}
                    />
                    <Controls.Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      text="Verify Code"
                      loading={signInLoading}
                    />
                  </>
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

FhxLogin.propTypes = {
  img: PropTypes.string,
};

FhxLogin.defaultProps = {
  img: '',
};

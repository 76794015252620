import React from 'react';
import PropTypes from 'prop-types';

import { Divider, Typography } from '@mui/material';

import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';
import ModuleEditForm from './ModuleEditForm';
import ActionAdditionForm from '../Actions/ActionAdditionForm';

// eslint-disable-next-line import/no-cycle
import renderActionList from '../Actions/renderActionList';
import { useApiClient } from '../../context/ApiClientContext';

export default function ModuleTreeItem(props) {
  const {
    id,
    actionListId,
    label,
    setFormPane,
    setTreeManagementServices,
    refreshParent,
    onDeleteWarningMessage,
    deleteService,
    raiseService,
    lowerService,
  } = props;

  const apiClient = useApiClient();

  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    expand,
    setExpand,
    setMCTreeItemChildNodes,
    updateLabel,
  } = useMedicalContentTreeItem(label);

  const fetchChildNodes = async () => {
    setMCTreeItemChildNodes(
      await renderActionList(
        apiClient,
        actionListId,
        setFormPane,
        setTreeManagementServices,
        fetchChildNodes,
      ),
    );
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const onSelect = () => {
    setFormPane(
      <>
        <ModuleEditForm id={id} updateCallback={updateLabel} refreshParent={refreshParent} />
        <Divider />
        <Typography variant="h6" gutterBottom>
          Add Actions
        </Typography>
        <ActionAdditionForm
          actionListId={actionListId}
          reloadCallback={reloadAndExpandNode}
          isModule
        />
      </>,
    );
    setTreeManagementServices({
      onDeleteWarningMessage,
      deleteService,
      raiseService,
      lowerService,
      onSuccess: refreshParent,
    });
  };

  return (
    <MedicalContentTreeItem
      id={id}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChilren="No actions yet..."
    />
  );
}

ModuleTreeItem.propTypes = {
  id: PropTypes.number.isRequired,
  actionListId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
  onDeleteWarningMessage: PropTypes.string.isRequired,
  deleteService: PropTypes.func.isRequired,
  raiseService: PropTypes.func,
  lowerService: PropTypes.func,
};

ModuleTreeItem.defaultProps = {
  raiseService: null,
  lowerService: null,
};

import React, { } from 'react';
import PropTypes from 'prop-types';

import useToast from '../../hooks/useToast';
import AttributeCrudForm from '../Attribute/attribute/AttributeCrudForm';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import basicServiceHandler from '../../services/basicServiceHandler';
import { attributeFieldValuesPropType } from '../Attribute/attribute/AttributeFormContent';

export default function QuestionFormSubFormAttribute(props) {
  const {
    attributeId, attributeFieldValues, questionId, refreshValues,
  } = props;

  const apiClient = useApiClient();

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  // Attribute pane callbacks
  // ------------------------

  const makeCallbacks = (service, successMessage, errorMessage) => ({
    service,
    onSuccess: () => {
      toastSetSuccessNotification(successMessage);
      refreshValues();
    },
    onFail: (error) => toastSetErrorNotification(`${errorMessage}: ${convertAxiosErrorToUserFacingMessage(error)}`),
  });

  const createAttributeCallbacks = makeCallbacks((
    attributeCreateRequest,
  ) => apiClient.createDefaultAttributeForQuestion(
    questionId,
    attributeCreateRequest,
  ), 'Attribute created.', 'Error creating Attribute');

  const addExistingAttributeCallbacks = makeCallbacks((
    attributeAddRequest,
  ) => apiClient.addExistingAttributeAsDefaultForQuestion(
    questionId,
    attributeAddRequest,
  ), 'Attribute added.', 'Error adding Attribute');

  const removeAttributeCallbacks = makeCallbacks(
    () => apiClient.removeDefaultAttributeFromQuestion(questionId),
    'Attribute removed',
    'Error removing Attribute',
  );

  // update is a bit different because service.updateAttribute returns just
  // the attribute, not the whole fixedAnswer. We do a fetch for the answerFormView again if the
  // update succeeds.
  const updateAttributeCallbacks = !attributeId ? null : {
    service: (attributeCreateUpdateRequest) => apiClient.updateAttribute(
      attributeId,
      attributeCreateUpdateRequest,
    ),
    onSuccess: () => basicServiceHandler(
      () => apiClient.getQuestion(questionId),
      () => {
        toastSetSuccessNotification('Attribute updated');
        refreshValues();
      },
      (error) => toastSetErrorNotification(
        `The Attribute was updated, but there was an error refreshing the Question. 
        ${convertAxiosErrorToUserFacingMessage(error)}`,
      ),
      () => {},
    ),
    onFail: (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
  };
  return (
    <AttributeCrudForm
      attributeFieldValues={attributeFieldValues}
      createCallbacks={createAttributeCallbacks}
      updateCallbacks={updateAttributeCallbacks}
      addExistingAttributeCallbacks={addExistingAttributeCallbacks}
      removeCallbacks={removeAttributeCallbacks}
    />
  );
}

QuestionFormSubFormAttribute.propTypes = {
  attributeId: PropTypes.number,
  attributeFieldValues: attributeFieldValuesPropType,
  questionId: PropTypes.number.isRequired,
  refreshValues: PropTypes.func.isRequired,
};

QuestionFormSubFormAttribute.defaultProps = {
  attributeId: null,
  attributeFieldValues: null,
};

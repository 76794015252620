import React from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../context/ApiClientContext';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';
import ScoreFactorPane from './ScoreFactorPane';

export default function ScoreFactorTreeItem(props) {
  const {
    scoreId, scoreFactor, label, setFormPane, refreshParent, setTreeManagementServices,
  } = props;

  const {
    MCTreeItemLabel,
    expand,
  } = useMedicalContentTreeItem(label);

  const apiClient = useApiClient();

  const onSelect = () => {
    setFormPane(<ScoreFactorPane
      scoreId={scoreId}
      initialScoreFactor={scoreFactor}
      refreshParent={refreshParent}
    />);
    setTreeManagementServices({
      onDeleteWarningMessage: `Delete the Score Factor "${label}"?`,
      deleteService: () => apiClient.deleteScoreFactor(scoreFactor.id),
      onSuccess: refreshParent,
    });
  };

  return (
    <MedicalContentTreeItem
      id={scoreFactor.id}
      MCTreeItemLabel={MCTreeItemLabel}
      isEndNode
      onSelect={onSelect}
      expand={expand}
    />
  );
}

ScoreFactorTreeItem.propTypes = {
  scoreId: PropTypes.number.isRequired,
  scoreFactor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    condition: PropTypes.shape({
      id: PropTypes.number,
      conditionType: PropTypes.string,
      subconditions: PropTypes.arrayOf(PropTypes.shape({ conditionType: PropTypes.string })),
    }).isRequired,
    points: PropTypes.number.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};

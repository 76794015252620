import PropTypes from 'prop-types';

const basicServiceHandler = (service, successCB, failCB, finalCB) => {
  service()
    .then((result) => {
      if (!result) return;
      if (result.status === 200 || result.status === 201) {
        successCB(result);
      } else {
        failCB(result);
      }
    }).catch((error) => {
      failCB(error);
    }).finally(() => {
      if (finalCB) {
        finalCB();
      }
    });
};

export default basicServiceHandler;

export const serviceCallbackPropTypes = PropTypes.shape({
  service: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
});

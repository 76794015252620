import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { Controls } from '../../components/controls/Controls';
import Form, { useForm } from '../../components/useForm';
import basicServiceHandler from '../../services/basicServiceHandler';
import { convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import ConditionCreateSubForm from '../Condition/ConditionCreateSubForm';

export default function ScoreFactorForm(props) {
  const {
    initialFieldValues, onSubmit, onSuccess, onFail,
  } = props;
  const [condition, setCondition] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updatedValues, setUpdatedValues] = useState(initialFieldValues);

  const {
    values,
    setValues,
    errors,
    setErrors,
  } = useForm(initialFieldValues);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (values.points === 0) {
      setErrors({ points: 'Points required.' });
      return;
    }
    const points = parseFloat(values.points);
    setLoading(true);
    basicServiceHandler(
      () => onSubmit({ points, condition }),
      () => { onSuccess(values); setUpdatedValues(values); },
      (error) => { onFail(convertAxiosErrorToUserFacingMessage(error)); setValues(updatedValues); },
      () => setLoading(false),
    );
  };

  const handleNumberChange = (e) => {
    const newValue = e.target.value.replace(/[^\d.-]+/g, '');
    setValues({ ...values, [e.target.name]: newValue });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          {condition === null
            ? (
              <>
                <Typography>
                  Select a Condition
                </Typography>
                <ConditionCreateSubForm
                  onSubmit={(result) => {
                    setCondition(result);
                    return Promise.resolve(null);
                  }}
                  onSuccess={onSuccess}
                  onFail={() => {}}
                />
              </>
            )
            : (
              <>
                <Typography>
                  How many points to add if the condition is true
                </Typography>
                <Controls.Input
                  value={values.points !== 0 ? values.points : ''}
                  name="points"
                  onChange={handleNumberChange}
                  label="Points"
                  inputProps={{ inputMode: 'numeric', pattern: '-?[0-9.]*' }}
                  error={errors.points}
                />

                <Grid container justifyContent="flex-end">
                  <Controls.Button
                    variant="contained"
                    type="submit"
                    score={{ margin: '6px' }}
                    loading={loading}
                    text="Save"
                    size="medium"
                  />
                </Grid>
              </>
            )}
        </Grid>
      </Grid>
    </Form>
  );
}

ScoreFactorForm.propTypes = {
  initialFieldValues: PropTypes.shape({
    points: PropTypes.number,
  }),
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};

ScoreFactorForm.defaultProps = {
  initialFieldValues: { points: 0 },
};

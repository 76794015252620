import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import Form, { useForm } from '../../components/useForm';
import basicServiceHandler from '../../services/basicServiceHandler';
import { convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import Dropdown from '../../components/controls/Dropdown';
import Button from '../../components/controls/Button';

export default function CategoryAndTagForm(props) {
  const {
    categories, tagsListByCategory, fetchTagsByCategoryId, onSubmit, onSuccess, onFail,
  } = props;

  const initialFieldValues = { categoryId: '', tagId: '' };
  const [loading, setLoading] = useState(false);
  const [updatedValues, setUpdatedValues] = useState(initialFieldValues);

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(initialFieldValues);

  const handleCategoryChange = (e) => {
    handleInputChange(e);
    fetchTagsByCategoryId(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    basicServiceHandler(
      () => onSubmit(values),
      () => { onSuccess(values); setUpdatedValues(values); },
      (error) => { onFail(convertAxiosErrorToUserFacingMessage(error)); setValues(updatedValues); },
      () => setLoading(false),
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={6}>
          <Dropdown
            label="Category"
            name="categoryId"
            value={values.categoryId}
            onChange={handleCategoryChange}
            options={categories.map((category) => ({ name: category.name, id: category.id }))}
          />
          <Dropdown
            label="Tag"
            name="tagId"
            value={values.tagId}
            onChange={handleInputChange}
            options={tagsListByCategory.map((tag) => ({ name: tag.name, id: tag.id }))}
          />
          <Box justifyContent="flex-end">
            <Button
              variant="contained"
              type="submit"
              sx={{ margin: '6px' }}
              loading={loading}
              text="Save"
              size="medium"
            />
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
}

CategoryAndTagForm.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  tagsListByCategory: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    categoryId: PropTypes.number.isRequired,
  })).isRequired,
  fetchTagsByCategoryId: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};
